<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Sites
          <v-spacer></v-spacer>
          <span class="subtitle-2">
            <v-dialog
              v-model="isDialogVisible"
              persistent
              max-width="600px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  small
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disableAdd"
                  @click="newItem"
                >
                  Add
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline" v-if="siteInfo.new !== false">New Site</span>
                  <span class="headline" v-else>Edit Site</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-alert
                    v-if="saveSiteError !== null"
                    type="error"
                    dense
                    dismissible
                  >
                    {{ saveSiteError }}
                  </v-alert>
                  <v-form ref="formNewSite">
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-select
                            v-model="siteInfo.site_nixt_id"
                            :items="siteList.nixt"
                            item-value="_id"
                            item-text="siteName"
                            :loading="isLoadingNixtSite"
                            label="Site (NiXT)"
                            dense
                            outlined
                            hide-details
                            :rules="[required]"
                            :disabled="!siteInfo.new"
                            @change="selectNewSite"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="siteInfo.site_name_en"
                            label="Site Name (EN)"
                            dense
                            outlined
                            hide-details
                            :rules="[required]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="siteInfo.site_name_th"
                            label="Site Name (TH)"
                            dense
                            outlined
                            hide-details
                            :rules="[required]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-menu
                            v-model="datePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="siteInfo.start_date"
                                label="Start Date"
                                readonly
                                dense
                                outlined
                                hide-details
                                v-bind="attrs"
                                class="shrink"
                                v-on="on"
                                :rules="[required]"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="siteInfo.start_date"
                              color="primary"
                              @input="datePicker = false"
                              no-title
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model.number="siteInfo.location"
                            label="Location"
                            dense
                            outlined
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="siteInfo.capacity"
                            label="Capacity"
                            dense
                            outlined
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model.number="siteInfo.pv_area"
                            label="PV Area"
                            dense
                            outlined
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model.trim="siteInfo.emi_id"
                            label="EMI ID"
                            dense
                            outlined
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    color="error"
                    outlined
                    :disabled="savingSite"
                    @click="closeNewSite"
                  >
                    Close
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :loading="savingSite"
                    @click="addNewSite"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>
        </v-card-title>
        <div>
          <v-data-table
            :headers="headers"
            :items="siteList.list"
            :items-per-page="5"
            :loading="tableLoading"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon
                class="me-4"
                color="error"
                small
                @click="editItem(item)"
              >
                {{ icons.mdiSquareEditOutline }}
              </v-icon>
              <v-icon
                color="error"
                small
                @click="deleteItem(item)"
              >
                {{ icons.mdiDelete }}
              </v-icon>
            </template>

            <template #[`item.devices`]="{ item }">
              <router-link :to="{ name: 'sites-devices', params: { siteId: item.site_id } }">
                <v-icon
                  class=""
                  color="info"
                  small
                >
                  {{ icons.mdiFormatListBulleted }}
                </v-icon>
              </router-link>
            </template>

            <template #[`item.microgrid`]="{ item }">
              <router-link :to="{ name: 'sites-microgrid', params: { siteId: item.site_id } }">
                <v-icon
                  class=""
                  color="grey darken-4"
                  small
                >
                  {{ icons.mdiCogs }}
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <confirm ref="veeConfirmRef"></confirm>
    </v-col>
  </v-row>
</template>
<script>

import {
  ref, reactive, onMounted, computed,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'
import {
  mdiDelete, mdiSquareEditOutline, mdiFormatListBulleted, mdiCogs,
} from '@mdi/js'
import store from '@/store'
import axios from '@axios'

/* eslint-disable no-underscore-dangle */
export default {
  components: {
    Confirm: () => import('@/components/Confirm.vue')
  },
  setup() {
    const siteList = reactive({
      list: [],
      nixt: [],
    })
    const tableLoading = ref(true)
    const veeConfirmRef = ref(null)
    const isDialogVisible = ref(false)
    const siteInfo = reactive({
      new: false,
      site_id: null,
      site_name_en: null,
      site_name_th: null,
      site_nixt_id: null,
      site_name_nixt: null,
      code: null,
      iot_hub_id: null,
      pv_area: 0,
      emi_id: null,
      start_date: null,
      capacity: null,
      location: null,
    })
    const datePicker = ref(false)
    const disableAdd = computed(() => siteList.nixt.filter(nixt => !siteList.list.map(item => item.site_id).includes(nixt._id)).length <= 0)

    const isLoadingNixtSite = ref(false)
    const loadNixtSite = () => {
      isLoadingNixtSite.value = true
      store.dispatch('sites/fetchNixtSites')
        .then(response => {
          if (typeof response.data.data.sites !== 'undefined') {
            // const siteIds = siteList.list.map(item => item.site_id)
            // siteList.nixt = response.data.data.sites.filter(item => !siteIds.includes(item._id))
            siteList.nixt = response.data.data.sites
          } else {
            siteList.nixt = []
          }
        })
        .finally(() => { isLoadingNixtSite.value = false })
    }
    const loadSiteList = () => {
      tableLoading.value = true
      store.dispatch('sites/fetchSites')
        .then(() => {
          siteList.list = store.state.sites.list
          tableLoading.value = false
          loadNixtSite()
        })
    }

    const selectNewSite = siteId => {
      const nixtInfo = siteList.nixt.find(item => item._id === siteId)
      siteInfo.site_id = nixtInfo._id
      siteInfo.site_name_nixt = nixtInfo.siteName
      siteInfo.iot_hub_id = nixtInfo.iotHubId
      siteInfo.code = nixtInfo.code
    }

    const formNewSite = ref(null)
    const saveSiteError = ref(null)

    const savingSite = ref(false)
    const addNewSite = () => {
      saveSiteError.value = null
      if (formNewSite.value.validate()) {
        savingSite.value = true
        axios
          .post(siteInfo.new ? '/device/siteadd' : '/device/siteupdate', {
            site_id: siteInfo.site_id,
            site_name_en: siteInfo.site_name_en,
            site_name_th: siteInfo.site_name_th,
            site_name_nixt: siteInfo.site_name_nixt,
            code: siteInfo.code,
            iot_hub_id: siteInfo.iot_hub_id,
            pv_area: siteInfo.pv_area,
            emi_id: siteInfo.emi_id !== null ? siteInfo.emi_id.split(',') : null,
            capacity: siteInfo.capacity,
            start_date: siteInfo.start_date,
            location: siteInfo.location,
          })
          .then(response => {
            const { code, message } = response.data
            if (code !== '0') {
              saveSiteError.value = message
            } else {
              loadSiteList()
              formNewSite.value.reset()
              isDialogVisible.value = false
            }
          })
          .catch(() => {
            saveSiteError.value = 'Error while processing'
          })
          .finally(() => { savingSite.value = false })
      }
    }
    const closeNewSite = () => {
      if (!savingSite.value) {
        isDialogVisible.value = false
        formNewSite.value.reset()

        return true
      }

      return false
    }

    const newItem = () => {
      siteInfo.new = true
      loadNixtSite()
    }

    const editItem = site => {
      isDialogVisible.value = true
      siteInfo.new = false
      siteInfo.site_nixt_id = site.site_id
      siteInfo.site_id = site.site_id
      siteInfo.site_name_en = site.site_name_en
      siteInfo.site_name_th = site.site_name_th
      siteInfo.site_name_nixt = site.site_name_nixt
      siteInfo.code = site.code
      siteInfo.iot_hub_id = site.iot_hub_id
      siteInfo.pv_area = site.pv_area
      siteInfo.emi_id = site.emi_id !== null ? site.emi_id.join(',') : null
      siteInfo.capacity = site.capacity
      siteInfo.start_date = site.start_date
      siteInfo.location = site.location ?? null
    }

    const deleteItem = site => {
      veeConfirmRef.value
        .open('Deleting Site', `Are you sure to delete '${site.site_name_en}' ?`, { color: 'error' })
        .then(confirm => {
          if (confirm === true) {
            axios
              .post('/device/siteremove', {
                site_id: `${site.site_id}`,
              })
              .then(response => {
                const { code, message } = response.data
                if (code === '0') {
                  loadSiteList()
                } else {
                  // Todo: display error
                }
              })
          }
        })
    }

    onMounted(() => {
      loadSiteList()
    })

    return {
      headers: [
        { text: 'CODE', value: 'code' },
        { text: 'NAME', value: 'site_name_en' },
        { text: 'UPDATE DATE', value: 'update_date' },
        {
          text: 'DEVICES', value: 'devices', align: 'center', sortable: false,
        },
        {
          text: 'MICROGRID', value: 'microgrid', align: 'center', sortable: false,
        },
        {
          text: 'ACTIONS', value: 'actions', align: 'center', sortable: false,
        },
      ],
      siteList,
      tableLoading,
      veeConfirmRef,
      isDialogVisible,
      isLoadingNixtSite,
      siteInfo,
      savingSite,
      datePicker,
      formNewSite,
      saveSiteError,
      disableAdd,

      loadNixtSite,
      selectNewSite,
      addNewSite,
      closeNewSite,
      newItem,
      editItem,
      deleteItem,

      icons: {
        mdiDelete,
        mdiSquareEditOutline,
        mdiFormatListBulleted,
        mdiCogs,
      },
      required,
    }
  },
}
</script>
